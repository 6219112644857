export default {
    SOURCE_LIST_REQUEST : "SOURCE_LIST_REQUEST",
    SOURCE_LIST_SUCCESS : "SOURCE_LIST_SUCCESS",
    SOURCE_LIST_FAILURE : "SOURCE_LIST_FAILURE",
    SOURCE_TYPE_REQUEST : "SOURCE_TYPE_REQUEST",
    SOURCE_TYPE_SUCCESS : "SOURCE_TYPE_SUCCESS",
    ACTION_SOURCE_ADD : "ACTION_SOURCE_ADD",
    ACTION_SOURCE_UPDATE : "ACTION_SOURCE_UPDATE",
    ACTION_SOURCE_DELETE : "ACTION_SOURCE_DELETE",
    FILTER_SOURCE_PAGINATION : "FILTER_SOURCE_PAGINATION",
}