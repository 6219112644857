

export const Images = {
  addlist: require("./addlist.svg"),
  Admin: require("./Admin.svg"),
  arrow: require("./arrow.svg"),
  arrowblack: require("./arrowblack.svg"),
  Back: require("./Back.svg"),
  calendardetails: require("./calendardetails.svg"),
  Chart: require("./Chart.svg"),
  close: require("./close.svg"),
  Dashboard: require("./Dashboard.svg"),
  Delete: require("./Delete.svg"),
  details: require("./details.svg"),
  downarrow: require("./downarrow.svg"),
  dropdownarrow: require("./dropdownarrow.svg"),
  Edit: require("./Edit.svg"),
  Fullscreen: require("./Fullscreen.svg"),
  Logout: require("./Logout.svg"),
  Maintennace: require("./Maintennace.svg"),
  Manual: require("./Manual.svg"),
  next: require("./next.svg"),
  Notification: require("./Notification.svg"),
  prev: require("./prev.svg"),
  Preview: require("./Preview.svg"),
  RowEdit: require("./RowEdit.svg"),
  Search: require("./Search.svg"),
  searchfilter: require("./searchfilter.svg"),
  Settings: require("./Settings.svg"),
  Support: require("./Support.svg"),
  whitedownarrow: require("./whitedownarrow.svg"),
  File: require("./File.svg"),
  profile: require("./profile.png"),
  SupportBG: require("./SupportBG.svg"),
  NavBar: require("./NavBar.svg"),
  supportform: require("./supportform.svg"),
  slack: require("./slack.svg"),
  folderarrow: require("./folderarrow.svg"),
  map: require("./map.svg"),
  reset: require("./Reset.png"),
  circletick: require("./CircleTick.svg"),
  circleclose: require("./CircleClose.svg"),
  circledelay: require("./CircleDelay.svg"),
  sort: require("./sort.svg"),
  Statistics: require('./bar-chart.svg'),
  trendUp: require('./trending-up.svg'),
  trendDown:require('./trending-down.svg'),
  mail:require('./mail.svg'),
  minimize:require("./minimize.svg"),
  testAdd:require("./testAdd.svg"),
  list: require("./list.svg"),
  testArrow:require("./testArrow.svg"),
  whitedelete:require("./WhiteDelete.svg"),
  testtick:require("./t-tick.png")
};
