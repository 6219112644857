export default {
    SLACK_LIST_REQUEST : "SLACK_LIST_REQUEST",
    SLACK_LIST_SUCCESS : "SLACK_LIST_SUCCESS",
    SLACK_LIST_FAILURE : "SLACK_LIST_FAILURE",
    FILTER_SLACK_LIST_REQUEST: "FILTER_SLACK_LIST_REQUEST",
    SOURCE_SLACK_LIST_SUCCESS: "SOURCE_SLACK_LIST_SUCCESS",
    ALERT_SLACK_LIST_SUCCESS: "ALERT_SLACK_LIST_SUCCESS",
    FILTER_SLACK_PAGINATION : "FILTER_SLACK_PAGINATION",
    ACTION_SLACK_ADD : "ACTION_SLACK_ADD",
    ACTION_SLACK_UPDATE : "ACTION_SLACK_UPDATE",
    ACTION_SLACK_DELETE : "ACTION_SLACK_DELETE",
}