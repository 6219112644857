import React from "react";
import "./Home.scss"
import { Loader } from "../../components"
const AccsssBlock = () => {

    return (
        <div className="nfcontainer">
            <Loader />
        </div>
    );
};

export default AccsssBlock;