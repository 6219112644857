export default {
    MAP_LIST_REQUEST : "MAP_LIST_REQUEST",
    MAP_LIST_SUCCESS : "MAP_LIST_SUCCESS",
    MAP_LIST_FAILURE : "MAP_LIST_FAILURE",
    ACTION_SOURCEMAP_ADD : "ACTION_SOURCEMAP_ADD",
    ACTION_SOURCEMAP_UPDATE : "ACTION_SOURCEMAP_UPDATE",
    ACTION_SOURCEMAP_DELETE : "ACTION_SOURCEMAP_DELETE",
    FILTER_MAP_PAGINATION : "FILTER_MAP_PAGINATION",
    SOURCE_MAP_LIST_SUCCESS: "SOURCE_MAP_LIST_SUCCESS",
    FILTER_MAP_LIST_REQUEST:"FILTER_MAP_LIST_REQUEST"
}