export default {
    USER_DETAILS_REQUEST : "USER_DETAILS_REQUEST",
    USER_DETAILS_SUCCESS : "USER_DETAILS_SUCCESS",
    USER_DETAILS_FAILURE : "USER_DETAILS_FAILURE",
    DASH_DETAILS_REQUEST: "DASH_DETAILS_REQUEST",
    DASH_DETAILS_SUCCESS : "DASH_DETAILS_SUCCESS",
    DASH_DETAILS_FAILURE : "DASH_DETAILS_FAILURE",
    TZ_PROFILE_REQUEST: "TZ_PROFILE_REQUEST",
    SOURCE_PROFILE_LIST_REQUEST: "SOURCE_PROFILE_LIST_REQUEST",
    SOURCE_PROFILE_LIST_SUCCESS: "SOURCE_PROFILE_LIST_SUCCESS",
    SOURCE_PROFILE_LIST_FAILURE : "SOURCE_PROFILE_LIST_FAILURE",
    SET_FULLSCREEN_REQUEST: "SET_FULLSCREEN_REQUEST"
}