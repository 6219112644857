import React from "react";
import "./App.scss";
import Main from "./pages";
import Dev from "./pages/dev";
import ScrollToTop from './ScrollController';
import { isDev } from './utils'
import {  BrowserRouter as Router } from "react-router-dom";

import { Security } from '@okta/okta-react';
const config = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  issuer: process.env.REACT_APP_ISSUER,
  redirectUri: window.location.origin+ '/implicit/callback',
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  disableHttpsCheck:false
};
function App() {
  return (
    <Router>
     <ScrollToTop />
        {false?
          <Dev />
        :
        <Security {...config}>
          <Main />
         </Security>
        }
    </Router>
  );
}

export default App;
