export default {
    MAINTENNACE_LIST_REQUEST : "MAINTENNACE_LIST_REQUEST",
    MAINTENNACE_LIST_SUCCESS : "MAINTENNACE_LIST_SUCCESS",
    MAINTENNACE_LIST_FAILURE : "MAINTENNACE_LIST_FAILURE",
    FILTER_MAINTENNACE_LIST_REQUEST: "FILTER_MAINTENNACE_LIST_REQUEST",
    SOURCE_MAINTENNACE_LIST_SUCCESS: "SOURCE_MAINTENNACE_LIST_SUCCESS",
    REASON_MAINTENNACE_LIST_SUCCESS: "REASON_MAINTENNACE_LIST_SUCCESS",
    FILTER_MAINTENNACE_PAGINATION : "FILTER_MAINTENNACE_PAGINATION",
    ACTION_MAINTENNACE_ADD : "ACTION_MAINTENNACE_ADD",
    ACTION_MAINTENNACE_UPDATE : "ACTION_MAINTENNACE_UPDATE",
    ACTION_MAINTENNACE_DELETE : "ACTION_MAINTENNACE_DELETE",
}