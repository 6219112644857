import React, { useEffect} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Maintennace from "./Maintennace";
import Support from "./Support";
import Configurations from "./Configuration"
import Source from "./Configuration/SubPages/Source"
import SourceMap from "./Configuration/SubPages/SourceMap"
import SlackIntegration from "./Configuration/SubPages/Slack"
import TestConfiguration from "./Configuration/SubPages/TestConfiguration"
import Profile from "./Profile"
import Admin from "./Admin"
import Details from "./Dashboard/Details"
import TrendChart from "./TrendChart"
import GeneralTrend from "./TrendChart/SubPages/General"
import OverAllTrend from "./TrendChart/SubPages/OverAll"
import FailureTrend from "./TrendChart/SubPages/Failure"
import Dashboard from "./Dashboard"; 
import NotFound from './NotFound'
import AccessBlock from './AccessBlock'
import ParticularTrend from './TrendChart/ParticularSource'
import axios from 'axios';
import { connect, useSelector } from "react-redux";

import {UserDetails} from "../reducers/user/actions"

function Main(props) {
  axios.defaults.headers.common['Authorization'] = 'Token 3c16b149993699327510049177f3008584232b7d';
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  useEffect(()=>{
    props.UserDetails();
  },[])
  return (
   
          <Switch>
            <Route path="/" exact children={<Dashboard />} />
            <Route path="/maintenance" children={<Maintennace />} />
            <Route path="/support-link" children={<Support />} />
            <Route path="/configurations" children={<Configurations />} />
            <Route path="/source-configurations" children={<Source />} />
            <Route path="/source-map-configurations" children={<SourceMap />} />
            <Route path="/slack-integration" children={<SlackIntegration />} />
            <Route path="/test-case-configurations" component={TestConfiguration} />
            <Route path="/profile" children={<Profile />} />
            <PrivateRoute path="/admin">
              <Admin />
            </PrivateRoute>
            <Route path="/source-details/:sourcename?" children={<Details />} />
            <Route path="/trend-chart" children={<TrendChart />} />
            <Route path="/trend-chart-general" children={<GeneralTrend />} />
            <Route path="/trend-chart-overall" children={<OverAllTrend />} />
            <Route path="/trend-chart-failure" children={<FailureTrend />} />
            <Route path="/trend-source-chart/:sourcename" children={<ParticularTrend />} />
            <Route path="/access-block" children={<AccessBlock />} />
            <Route component={NotFound}/>
          </Switch>
 
  );
}
function PrivateRoute({ children, ...rest }) {
  const userdata = useSelector(state => state.user);
  const {user} = userdata;
  let gplist = user && user.groups? user.groups.map((e)=>e.name): [];
  if(gplist.length > 0){
  let allow = false;
  if(gplist.indexOf("Configuration Admin") > -1){
    allow = true;
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
      true ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/access-block",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
  }
  else{
    return null;
  }
}
export default connect(
  null, { UserDetails }
)(Main);
