export default {
    TREND_SOURCE_LIST_REQUEST : "TREND_SOURCE_LIST_REQUEST",
    TREND_SOURCE_LIST_SUCCESS : "TREND_SOURCE_LIST_SUCCESS",
    TREND_SOURCE_LIST_ERROR : "TREND_SOURCE_LIST_ERROR",
    OVERALL_LIST_REQUEST: "OVERALL_LIST_REQUEST",
    OVERALL_LIST_SUCCESS: "OVERALL_LIST_SUCCESS",
    OVERALL_LIST_ERROR: "OVERALL_LIST_ERROR",
    GENERAL_LIST_REQUEST: "GENERAL_LIST_REQUEST",
    GENERAL_LIST_SUCCESS: "GENERAL_LIST_SUCCESS",
    GENERAL_LIST_ERROR: "GENERAL_LIST_ERROR",
    FAILURE_LIST_REQUEST: "FAILURE_LIST_REQUEST",
    FAILURE_LIST_SUCCESS: "FAILURE_LIST_SUCCESS",
    FAILURE_LIST_ERROR: "FAILURE_LIST_ERROR",
    SOURCE_TREND_LIST_SUCCESS: "SOURCE_TREND_LIST_SUCCESS",
    SOURCE_TREND_LIST_REQUEST: "SOURCE_TREND_LIST_REQUEST",
    SOURCE_TREND_LIST_ERROR: "SOURCE_TREND_LIST_ERROR"
}